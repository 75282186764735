import https from "./https"
import store from "@/store"

const downloadFileApi = {
  list (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/download-files`, { params })
  },
  show (downloadFileId) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/download-files/${downloadFileId}`)
  },
}

export default downloadFileApi
